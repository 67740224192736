import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { ApiProvider } from 'Api.js';
import ReactNotification from 'react-notifications-component'

import './index.css';
import './animate.css';
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';

// add polyfills
Object.fromEntries = Object.fromEntries || (
  arr => arr.reduce(
    (acc, [k, v]) => { acc[k] = v; return acc; }, 
    {}
  )
);

// render app
ReactDOM.render(
  <BrowserRouter>
    <ApiProvider>
      <ReactNotification />
      <App />
    </ApiProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
