import React from 'react';
import {
  withApi
} from 'Api';
import {
  Row,
  Col,
  ListGroupItem, 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import Tree from 'components/tree';
import classNames from 'classnames';

export default withApi(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      opened: {}
    };
  }
  
  async componentDidMount() {
    this.setState({
      categories: await this.props.api.getCategories()
    });
  }
  
  saveCategoryWithName = async (parent=null, id=null, curr='') => {
    const name = prompt("Naam voor de nieuwe categorie?", curr);
    if (name) {
      const category = await this.props.api.saveCategory(id, parent, name);
      const index = this.state.categories.findIndex(n => n.id === category.id);
      if (index !== -1) {
        const categories = [...this.state.categories];
        categories[index] = category;
        this.setState({
          categories
        });
      } else {
        this.setState({
          categories: [...this.state.categories, category]
        });
      }
    }
  }

  removeCategory = async (id, name) => {
    if (window.confirm("Weet je zeker dat je " + name + " wilt verwijderen?")) {
      await this.props.api.removeCategory(id);
      this.setState({
        categories: this.state.categories.filter(n => n.id !== id)
      });
    }
  }

  toggleInMenuBar = async (id) => {
    await this.props.api.toggleCategoryInMenuBar(id);
    const index = this.state.categories.findIndex(n => n.id === id);
    const categories = [...this.state.categories];
    categories[index] = {
      ...categories[index],
      inMenuBar: !(categories[index].inMenuBar)
    };
    this.setState({
      categories
    });
  }
  
  getChildren = (node) => {
    return this.state.categories.filter(n => n.parent === node.id);
  }
  
  renderNode = (node, parent, children, depth, lpadding, ToggleTriangle, toggle, isLast, opened) => {
    return (
      <>
        <ListGroupItem 
          className={classNames(
            'category',
            {
              'rounded-0': parent, 
              'border-bottom-0': false, 
            }
          )}
        >
          <div style={{ paddingLeft: `${lpadding}px` }}>
            <ToggleTriangle onClick={toggle}/>
            <span onClick={toggle}>
              {node.name}
            </span>
            <span style={{ 'float': 'right' }}>
              <FontAwesomeIcon icon={faPlus} className="ml-3" onClick={() => this.saveCategoryWithName(node.id)} />
              <FontAwesomeIcon icon={faPencilAlt} className="ml-3" onClick={() => this.saveCategoryWithName(parent ? parent.id : null, node.id, node.name)} />
              <FontAwesomeIcon icon={faTrash} className="ml-3" onClick={() => this.removeCategory(node.id, node.name)} />
              <FontAwesomeIcon 
                icon={node.inMenuBar ? faEye : faEyeSlash} 
                className="ml-3" 
                onClick={depth === 0 ? () => this.toggleInMenuBar(node.id) : () => {}}
                style={depth === 0 ? {} : { color: 'transparent' }}
              />
            </span>
          </div>
        </ListGroupItem>      
      </>      
    );    
  }
  
  render() {
    return (
      <>
        <Row>
          <Col>
            <h1 className="my-4">Geregistreerde categorieen</h1>
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <Tree              
              nodes={this.state.categories.filter(n => n.parent === null)} 
              renderNode={this.renderNode}
              getChildren={this.getChildren}
            />
            <ListGroupItem className={classNames('category')}>
              <span className={classNames('no-arrow-right')}/>
              <span>
                <FontAwesomeIcon icon={faPlus} onClick={() => this.saveCategoryWithName()} />
              </span>
            </ListGroupItem>      
          </Col>
        </Row>
      </>
    )
  }
});