import React, { useState, useEffect } from 'react';
import { splitTextOnNewLine } from 'formUtils';
import { withApi } from 'Api';
import {
  Row,
  Col,
  Input,
  Button
} from 'reactstrap';
import { 
  NavLink
} from 'react-router-dom';
import './style.scss';

const statusMapping = {
  "Ontvangen": "Bestelling ontvangen",
  "Betaling ontvangen": "Betaling ontvangen",
  "Opgestuurd": "Verstuurd/opgehaald",
  "Gesloten": "Afgehandeld",
  "Geannuleerd": "Geannuleerd"
};

const PaymentNames = {
  'ideal': 'iDeal',
  'manual': 'Handmatige overschrijving',
  'inPerson': 'Betalen bij het ophalen'
}

export default withApi((props) => {
  const [order, setOrder] = useState(null);
  
  useEffect(() => {
    (async () => {
      setOrder(await props.api.getOrder(props.match.params.id, props.match.params.code));
    })();
  }, [props.api, props.match, props.match.params.id, props.match.params.code]);
  
  if (order === null) {
    return null;
  }
  
  const renderRow = (row) => {
    return (
      <tr>
        <td>
          <div className="order-item-image-container">
            <img src={props.config.dynamic_product_images + row.item.images[0]}/>
          </div>
        </td>
        <td>
          <NavLink to={`/item/${row.item.id}`} target="_blank">
            {row.item.name}
          </NavLink>
        </td>
        <td>
          {row.size.name}
        </td>
        <td>
          {row.qty}
        </td>
        <td style={{ textAlign: 'right' }}>
          € {row.price}
        </td>
        <td style={{ textAlign: 'right' }}>
          € {row.totalPrice}
        </td>
      </tr>      
    )
  }

  const changeState = async (event) => {
    setOrder(await props.api.setOrderStatus(props.match.params.id, props.match.params.code, event.target.value));
  }
  
  const cancelOrder = async () => {
    if (window.confirm("Weet je zeker dat je deze order wilt annuleren"))
      setOrder(await props.api.cancelOrder(props.match.params.id, props.match.params.code));
  }

  return (
    <>
      <Row className="my-4">
        <Col>
          <table style={{marginLeft: '0px', marginRight: 'auto', width: '100%'}}>
            <tr>
              <th>Besteld door:</th>
              <td>{order.purchaserName}</td>
            </tr>
            <tr>
              <th>Ordernummer:</th>
              <td>{order.id}</td>
            </tr>
            <tr>
              <th>Orderdatum:</th>
              <td>{order.orderDate}</td>
            </tr>
            <tr>
              <th>Orderstatus:</th>
              <td>{statusMapping[order.status]}</td>
            </tr>
            <tr>
              <th>Betalingsmethode:</th>
              <td>{PaymentNames[order.paymentMethod]}</td>
            </tr>
            <tr>
              <th>Straat & huisnummer:</th>
              <td>{order.address.street} {order.address.houseNumber}</td>
            </tr>
            <tr>
              <th>Postcode:</th>
              <td>{order.address.postalCode}</td>
            </tr>
            <tr>
              <th>Woonplaats:</th>
              <td>{order.address.city}</td>
            </tr>
            <tr>
              <th>Verzendmethode:</th>
              <td>{order.shippingMethod === 'pickup' ? 'Ophalen op afspraak' : 'Bestelling wordt verzonden'}</td>
            </tr>
            <tr>
              <th style={{ verticalAlign: 'top' }}>Opmerkingen:</th>
              <td>{splitTextOnNewLine(order.remarks)}</td>
            </tr>
          </table>
          <hr/>
          <table style={{marginLeft: 'auto', marginRight: 'auto'}} className="products">
            <tr>
              <th></th>
              <th>Naam</th>
              <th>Maat</th>
              <th>Aantal</th>
              <th>Prijs per stuk</th>
              <th>Totaal</th>
            </tr>
            
            {order.rows.map(renderRow)}
      
            <tr style={{borderTop: '1px solid black'}}>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2" style={{ textAlign: 'right' }}>
                Subtotaal:
              </td>
              <td style={{ textAlign: 'right' }}>
                € {order.totalPriceNoShipping}
              </td>
            </tr>
  
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2" style={{ textAlign: 'right' }}>
                Verzendkosten:
              </td>
              <td style={{ textAlign: 'right' }}>
                € {order.shippingPrice}
              </td>
            </tr>
  
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2" style={{ textAlign: 'right' }}>
                Totaal:
              </td>
              <td style={{ textAlign: 'right' }}>
                € {order.totalPrice}
              </td>
            </tr>
          </table>
          
          
          {order.status === "Ontvangen" && splitTextOnNewLine(props.api.config.confirmationEmailFooterText)}
        </Col>
      </Row>
      { props.api.loggedIn && (
        <Row className="my-4">
          <Col>
            <Input type='select' value={order.status} onChange={changeState} disabled={order.status === "Geannuleerd"}>
              {["Ontvangen", "Betaling ontvangen", "Opgestuurd", "Gesloten"].map(val => (
                <option value={val}>{statusMapping[val]}</option>
              ))}
            </Input>
          </Col>
          <Col>
            <Button block onClick={cancelOrder}>Cancel order</Button>
          </Col>
        </Row>
      )}
    </>
  );
});
