import React from 'react';
import { Button, Form } from 'reactstrap';
import { formOnChange, formInput } from 'formUtils';
import { withApi } from 'Api';
import './style.scss';

export default withApi(class extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      username: '',
      password: '',
    };
  }
  
  formOnChange = formOnChange(this);
  formInput = formInput(this);

  login = async e => {
    e.preventDefault();
    const loggedIn = await this.props.api.login(
      this.state.username,
      this.state.password
    );
    
    if (loggedIn) {
      if (this.props.history)
        this.props.history.push('/')
      else if (this.props.onSuccess)
        this.props.onSuccess();
    } else {
      this.setState({
        username: '',
        password: '',
      });
    }
  };

  render() {
    if (this.props.api.loggedIn) {
      return (
        <div className="login-form">
          <Button color="primary" block onClick={this.props.api.logout}>Log uit</Button>
        </div>
      );
    }
    
    return (
      <div className="login-form">
        <Form onSubmit={this.login}>
          {this.formInput('username', 'username')}
          {this.formInput('password', 'password', 'password')}
          <Button color="primary" block>Log in</Button>
        </Form>
      </div>
    );
  }
});