import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  CustomInput,
  Card,
  CardHeader,
  CardBody,
  Collapse
} from 'reactstrap';
import { withApi } from 'Api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { JoditConfig, formOnChange, formInput, formCustomInput } from 'formUtils';
import {
  SortableContainer, 
  SortableElement
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import set from 'lodash/set';
import get from 'lodash/get';
import JoditEditor from "jodit-react";

const SortableItem = SortableElement(({folder, img, removeImage}) => {
  return (
    <div className="SortableImage mx-1">
      <img src={folder + img.src} draggable={false} alt="product" />
      <span className="trash px-2" onClick={() => removeImage(img)}>
        <FontAwesomeIcon icon={faTrash} />
      </span>
    </div>
  );
});

const SortableList = SortableContainer(({folder, images, removeImage}) => {
  return (
    <div className="SortableImageContainer mb-3">
      {images.map((img, index) => (
        <SortableItem key={`item-${img}`} index={index} folder={folder} img={img} removeImage={removeImage} />
      ))}
    </div>
  );
});

const LockedField = (props) => {
  const [locked, setLocked] = useState(true);
  return (
    <>
      <Row>
        <Col xs={10}>
          {props.children(locked, setLocked)}
        </Col>
        <Col xs={2}>
          <Button block onClick={() => setLocked(!locked)}>{locked ? 'unlock' : 'lock'}</Button>
        </Col>
      </Row>
    </>
  );
}

const PageEditor = ({title, value, saveValue}) => {
  const [open, setOpen] = useState(false);
  
  return (
    <div className="mb-5">
      <Card>
        <CardHeader onClick={() => setOpen(!open)}>{title}</CardHeader>
        <Collapse isOpen={open}>
          <CardBody>
            <JoditEditor
              config={JoditConfig}
              value={value}
              onBlur={newContent => saveValue(newContent)}
            />
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
}

export default withApi(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  
  formOnChange = formOnChange(this);
  formInput = formInput(this);
  formCustomInput = formCustomInput(this);
  
  async componentDidMount() {
    this.setState({
      ...(await this.props.api.getSettings()),
      loading: false
    });
  }
  
  saveSettings = async () => {
    const {
      loading,
      ...settings
    } = this.state;
    await this.props.api.saveSettings(settings);
    try {
      if (window.opener && window.opener !== window) {
        window.opener.focus(); 
        window.close();
      }
    } catch(err) {
      console.log("Couldn't access opener");
    }
  }
  
  addImages = (path, type) => {
    return async (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const results = await this.props.api.uploadFiles(files, type);
      const images = [...get(this.state, path)];
      results.forEach(r => {
        if (!r.success) {
          alert(r.original + " kon niet worden opgeslagen!");
          return;
        }
        images.push({
          src: r.name,
          altText: "slide",
          captionHeader: "",
          captionText: ""
        });
      })
  
      // store the result
      this.setState(set({}, path, images));
    };
  }
  
  removeImage = (path) => {
    return (img) => {
      this.setState(set({}, path, get(this.state, path).filter(i => i !== img)));
    };
  }
  
  onSortEnd = (path) => {
    return ({ oldIndex, newIndex }) => {
      this.setState(set({}, path, arrayMove(get(this.state, path), oldIndex, newIndex)));
    };
  }
  
  renderBannerImageSetter = (path, type, folder) => {
    return (
      <>
        <SortableList 
          axis="x"
          lockAxis="x"
          distance={5}
          onSortEnd={this.onSortEnd(path)} 
          images={get(this.state, path)} 
          folder={folder}
          removeImage={this.removeImage(path)}
        />
        <FormGroup style={{ overflow: 'hidden' }}>
          <CustomInput type="file" id="imageUploadPicker" label="Kies een foto" onChange={this.addImages(path, type)} multiple />
        </FormGroup>
      </>
    )
  }
  
  render() {
    if (this.state.loading) {
      return null;
    }
    
    return (
      <Row className="my-4">
        <Col>
          <h3>Settings</h3>
          <LockedField>{locked => this.formInput('websiteUrl', 'website url (ex: https://4kidskindermode.nl)', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('hostEmail', 'E-mail', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('molliePaymentsKey', 'API key for mollie payments', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('cartValidity', 'Hoe lang moet een winkelwagen blijven bestaan na de laatste actie', 'number', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('footerUserColumn', 'Middelste footer kolom tekst', 'textarea', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('shipping.from', 'Vanaf hoeveel euro minimaal verzenden', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('shipping.price', 'Hoeveel euro zijn de verzendkosten', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('social.facebook', 'facebook url', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('social.whatsapp', 'whatsapp url', 'text', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('confirmationEmailFooterText', 'Confirmation email footer', 'textarea', { disabled: locked })}</LockedField>
          <LockedField>{locked => this.formInput('paymentReceivedResponse', 'Betaling ontvangen text ($CLIENT_NAME$ wordt vervangen door de klantnaam)', 'textarea', { disabled: locked })}</LockedField>
          <hr/>
        
          <h3>Home page</h3>
          {this.renderBannerImageSetter('homeBannerImages', 'homeBanner', this.props.config.dynamic_banner_home_images)}
          {this.formInput('homePageBanner', 'home page banner')}
          <div className="mb-5">
            <JoditEditor
              config={JoditConfig}
              value={get(this.state, 'homePageText')}
              onBlur={newContent => {
                this.formOnChange('homePageText', newContent);
              }}
            />
          </div>
          <hr/>

          <h3>Store page banner</h3>
          {this.renderBannerImageSetter('storeBannerImages', 'storeBanner', this.props.config.dynamic_banner_store_images)}
          <hr/>


          <h3>Pagina inhoud</h3>
          <PageEditor 
            title={'Algemene voorwaarden'} 
            value={get(this.state, 'algemeneVoorwaardenText')} 
            saveValue={newContent => this.formOnChange('algemeneVoorwaardenText', newContent)}
          />
          <hr/>

          <PageEditor 
            title={'Over ons'} 
            value={get(this.state, 'aboutUsText')} 
            saveValue={newContent => this.formOnChange('aboutUsText', newContent)}
          />
          <hr/>

          <PageEditor 
            title={'Verzend en retour beleid'} 
            value={get(this.state, 'verzendBeleidText')} 
            saveValue={newContent => this.formOnChange('verzendBeleidText', newContent)}
          />
          <hr/>

          <PageEditor 
            title={'Betaal methodes'} 
            value={get(this.state, 'betaalMethodesText')} 
            saveValue={newContent => this.formOnChange('betaalMethodesText', newContent)}
          />
          <hr/>

          <PageEditor 
            title={'FAQ'} 
            value={get(this.state, 'FAQText')} 
            saveValue={newContent => this.formOnChange('FAQText', newContent)}
          />
          <hr/>

          <Button 
            block 
            className="mb-3" 
            onClick={this.saveSettings}
          >
            Sla op
          </Button>
        </Col>
      </Row>
    )
  }
});