import React, { useState } from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
  Button,
  Form,
} from 'reactstrap';
import { formStateProxy, formInput } from 'formUtils';

export default withApi((props) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    captcha: '',
    tried: 0,
  });
  const proxy = formStateProxy(state, setState);
  const input = formInput(proxy);
    
  const sendMessage = async (e) => {
    e.preventDefault();
    const result = await props.api.submitContactForm(state);
    if (result.badCaptcha) {
      props.api.addAlert('danger', 'Captcha is incorrect', '');
      setState({
        ...state,
        captcha: '',
        tried: state.tried + 1
      })
    } else {
      props.api.addAlert('success', 'Het bericht is verstuurd', '');
      setState({
        name: '',
        email: '',
        subject: '',
        message: '',
        captcha: '',
        tried: state.tried + 1
      })
    }
  }

  return (
    <Row className="my-4">
      <Col>
        <Form onSubmit={sendMessage}>
          {input('name', 'Naam')}
          {input('email', 'Email')}
          {input('subject', 'Onderwerp')}
          {input('message', 'Bericht', 'textarea', { rows: 10 })}
          <div className="text-center my-4">
            <img src="/api/v1/contact/captcha" key={state.tried} alt="captcha"/>
          </div>
          {input('captcha', 'Wat zijn de 4 letters in de afbeelding hierboven')}
          <Button block>
            stuur bericht
          </Button>
        </Form>
      </Col>
    </Row>
  );
});
