import React, { useEffect } from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { 
  NavLink
} from 'react-router-dom';
import './style.scss';

export default withApi((props) => {  
  const setNewQty = (id, qty) => props.api.updateCartRowQty(id, qty);
  const removeRow = (id) => props.api.removeCartRow(id);
  
  useEffect(() => {
    if (!props.isVerification) {
      props.api.updateShoppingCart();
    }
  }, []);
  
  if (props.api.shoppingCart.rows.length === 0) {
    return (
      <Row className="my-4">
        <Col className="text-center">
          <h2>Er zijn geen artikelen aanwezig in uw winkelwagen</h2>
        </Col>
      </Row>
    );
  }
  
  const renderRow = (row) => {
    return (
      <>
        <Row>
          <Col sm="3">
            <div className="cart-item-image-container">
              <img src={props.config.dynamic_product_images + row.item.images[0]} alt=""/>
            </div>
          </Col>
  
          <Col sm="6">
            <h3 className="my-0">
              <NavLink to={`/item/${row.item.id}`}>
                {row.item.name}
              </NavLink>
            </h3>
            Maat {row.size.name}
            
            {!props.isVerification && (
              <Input type="select" value={row.qty} onChange={(e) => setNewQty(row.id, e.target.value)}>
                {[...Array(row.maxQty).keys()].map(i => (
                  <option key={i} value={i + 1}>{i + 1} stuk(s)</option>  
                ))}
              </Input>
            )}
            {props.isVerification && (
              <>
                <br/>
                <b>Aantal:</b> {row.qty}
              </>
            )}
            
            {!props.isVerification && (
              <Button color="link" className="remove-from-cart px-0" onClick={() => removeRow(row.id)}>
                <FontAwesomeIcon icon={faTrash} /> Verwijder uit winkelwagen
              </Button>
            )}
          </Col>
  
          <Col sm="3" className="price-col">
            € {row.totalPrice}
          </Col>
        </Row>
        <hr/>
      </>
    )
  }

  return (
    <Row className="my-4">
      <Col>
        {props.api.shoppingCart.rows.map(renderRow)}
        
        <Row>    
          <Col className="price-col">
            <div style={{float: 'right', paddingLeft: '40px'}}>
              € {props.api.shoppingCart.totalPriceNoShipping}<br/>
              € {props.shippingPrice !== undefined ? props.shippingPrice : props.api.shoppingCart.shippingPrice}<br/>
              € {props.totalPrice !== undefined ? props.totalPrice : props.api.shoppingCart.totalPrice}
            </div>
            <div style={{float: 'right'}}>
              Subtotaal: <br/>
              Verzendkosten{!props.isVerification && ' (vervalt bij ophalen)'}: <br/>
              Totaal: 
            </div>
          </Col>
        </Row>

        {!props.isVerification && (
          <Row>
            <Col sm="3">
            </Col>
    
            <Col sm="5">
              <Button block onClick={() => props.history.push("/create_order")}>
                Rond bestelling af
              </Button>
            </Col>
    
            <Col sm="3">
            </Col>
          </Row>
        )}

      </Col>
    </Row>
  );
});
