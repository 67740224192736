import React from 'react';
import {
  Col,
  Card, 
  CardImg, 
  CardBody,
  CardTitle, 
} from 'reactstrap';
import { 
  NavLink
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCopy, faTrash, faEye, faEyeSlash, faEuroSign, faPercent, faFire } from '@fortawesome/free-solid-svg-icons';

const Item = (props) => {
  const spacing = "mx-1";
  const itemLink = "/item/" + props.item.id;
  if (!props.loggedIn && !props.item.visible) {
    return null;  
  }
  
  let price = <>€ {props.item.price}</>;
  if (props.item.inDiscount) {
    price = (
      <>
        <span style={{ textDecoration: 'line-through', color: 'red', fontSize: '1rem' }} className="mr-2">€ {props.item.price}</span>
        <br/>
        € {props.item.discountPrice}
      </>
    );
  }

  return (
    <Col lg="3" md="4" className="mb-4">
      <Card className="h-100">
        <NavLink to={itemLink} className="item-card-image-container">
          <CardImg className="item-card-image" top src={props.config.dynamic_product_images + props.item.images[0]} alt=""/>
        </NavLink>
        <CardBody>
          <CardTitle>
            <NavLink to={itemLink}>
              {props.item.name}
            </NavLink>
          </CardTitle>
          <h5>
            {price}
          </h5>
        </CardBody>        
        { props.loggedIn && (
          <>
            <div className="item-editor-menu">
              <FontAwesomeIcon className={spacing} icon={faFire} onClick={() => props.markHot(props.item.id)} />
              <FontAwesomeIcon className={spacing} icon={faEdit} onClick={() => props.edit(props.item.id)} />
              <FontAwesomeIcon className={spacing} icon={faCopy} onClick={() => props.copy(props.item.id)} />
              <FontAwesomeIcon className={spacing} icon={props.item.visible ? faEye : faEyeSlash} onClick={() => props.toggleVisible(props.item.id)} />
              <FontAwesomeIcon className={spacing} icon={props.item.inDiscount ? faPercent : faEuroSign} onClick={() => props.toggleDiscount(props.item.id)} />
              <FontAwesomeIcon className={spacing} icon={faTrash} onClick={() => props.trash(props.item.id)} />
              <br/>
              <span className={spacing}>views: {props.item.views}</span>
            </div>
          </>
        )}
      </Card>
    </Col>
  );
}
export default Item;