import React, { Component } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Login from 'components/login'
import { store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';

const ApiContext = React.createContext();
const STORED_TOKEN = 'token';

export function withApi(Component) {
  return function WrapperComponent(props) {
    return <ApiContext.Consumer>{state => <Component {...props} api={state} config={state.config} />}</ApiContext.Consumer>;
  };
}

export class ApiProvider extends Component {
  constructor(props) {
    super(props);
  
    this.instance = axios.create({
      baseURL: '/api/v1',
      headers: {
        Accept: 'application/json',
      },
    });
    this.instance.interceptors.response.use(
      response => { return response; },
      error => {
        if (error.response.status === 401) {
          this.logout();
          this.setState({
            showInlogModal: true,
          });
        }
        if (error.response.status === 500) {
          this.addNotification('danger', '', error.response.data.error);
        }
        return Promise.reject(error);
      },
    );
    this.instance.interceptors.request.use(config => {
      if (this.state.token) {
        config.headers.Authorization = this.state.token;
      }
      return config;
    });
  
    this.state = {
      config: null,
      token: null,
      showInlogModal: false,
      shoppingCart: []
    };
  }
  
  async componentDidMount() {
    window.setShoppingCart = this.setShoppingCart;
    
    // store the start state
    const config = await this.get('/config/');
    const shoppingCart = await this.get('/cart/');
    console.log(config, shoppingCart);
    this.setShoppingCart(shoppingCart);
    this.setState({
      token: localStorage.getItem(STORED_TOKEN),
      config,
      shoppingCart
    });
  }
  
  componentWillUnmount() {
    window.setShoppingCart = null;
  }
  
  // Login functionality
  login = async (username, password) => {
    const key = await this.post('/login', { user: username, pass: password });
    if (!key) {
      return false;
    }
    
    // store the key
    localStorage.setItem(STORED_TOKEN, key);
    this.setState({
      token: key
    });
    return true;
  };
  
  logout = async () => {
    await this.post('/logout', {});
    localStorage.removeItem(STORED_TOKEN);
    this.setState({
      token: null,
    });
  };
  
  closeLoginDialog = () => {
    this.setState({
      showInlogModal: false,
    });
  }
  
  addNotification = (type, title, msg) => {
    store.addNotification({
      title: title || <div/>,
      message: msg || <div/>,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        pauseOnHover: true,
        onScreen: true
      }
    })
  }
  
  uploadFiles =  async (files, type) => {
    const formData = new FormData();
    for( var i = 0; i < files.length; i++ ){
      formData.append('files[' + i + ']', files[i]);
    }
    
    return (await this.instance.post(
      '/upload/'+type, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )).data;
  }
  
  updateShoppingCart = async () => {
    this.setShoppingCart(await this.get('/cart/'));
  }
  
  setShoppingCart = (shoppingCart) => {
    this.setState({
      shoppingCart
    });
  }
  
  addToCart = async (itemId, sizeId, qty, price) => {
    const shoppingCart = await this.post('/cart/add/', {
      itemId,
      sizeId,
      qty,
      price
    });
    this.setShoppingCart(shoppingCart);
    this.addNotification('success', 'Het artikel zit nu in de winkelwagen', (
      <>
        Klik{' '}
        <NavLink to="/shoppingcart">
          hier
        </NavLink>
        {' '}om naar de winkelwagen te gaan
      </>
    ));
  }
  
  removeCartRow = async (id) => {    
    this.setShoppingCart(await this.delete(`/cart/${id}/`));
  };
  
  updateCartRowQty = async (id, qty) => {
    this.setShoppingCart(await this.post(`/cart/${id}/qty/`, { qty }));
  };
  
  createOrder = async (addressData) => {
    const result = await this.post(`/order/create/`, addressData);
    this.setShoppingCart(result.shoppingCart);
    return result;
  }
  
  get = async (url) => {
    return (await this.instance.get(url)).data;
  };
  
  post = async (url, data) => {
    return (await this.instance.post(url, data)).data;
  };

  delete = async (url) => {
    return (await this.instance.delete(url)).data;
  };
  
  render() {
    const { children } = this.props;
  
    return (
      <ApiContext.Provider
        value={{
          config: this.state.config,
          shoppingCart: this.state.shoppingCart,
          login: this.login,
          logout: this.logout,
          loggedIn: this.state.token, 
          
          addAlert: this.addNotification,
          
          addToCart: this.addToCart,
          removeCartRow: this.removeCartRow,
          updateCartRowQty: this.updateCartRowQty,
          updateShoppingCart: this.updateShoppingCart,
          
          getItem: (id) => this.get(`/item/${id}/`),
          getItems: (filter=null) => this.post('/item/filtered/', filter),
          saveItem: (data) => this.post('/item/save/', data),
          removeItem: (id) => this.delete(`/item/${id}/`),
          copyItem: (id) => this.get(`/item/${id}/copy/`),
          toggleItemVisible: (id) => this.get(`/item/${id}/toggleVisible/`),
          toggleItemDiscount: (id) => this.get(`/item/${id}/toggleDiscount/`),
          markItemAsHot: (id) => this.get(`/item/${id}/markAsHot/`),
          
          getSizes: () => this.get('/size/'),
          saveSize: (id, name) => this.post('/size/save/', { id, name }),
          removeSize: (id) => this.delete(`/size/${id}`),
          sizesReorder: (sizes) => this.post('/size/reorder', { sizes }),
          
          getCategories: () => this.get('/category/'),
          toggleCategoryInMenuBar: (id) => this.post(`/category/${id}/toggleVisible/`),
          saveCategory: (id, parent, name) => this.post('/category/save', { id, name, parent }),
          removeCategory: (id) => this.delete(`/category/${id}/`),
          
          getSettings: () => this.get('/settings/getForEdit'),
          saveSettings: (newSettings) => this.post('/settings/save', newSettings),
          
          createOrder: this.createOrder,
          getOrders: () => this.get('/order/'),
          getOrder: (id, code) => this.get(`/order/${id}/${code}/`),
          cancelOrder: (id, code) => this.get(`/order/${id}/${code}/cancel/`),
          setOrderStatus: (id, code, status) => this.get(`/order/${id}/${code}/status/${status}/`),
          
          submitContactForm: (data) => this.post('/contact/send/', data),
          
          uploadFiles: this.uploadFiles,
        }}
      >
        {this.state.config && children}      
        {!this.state.config && (
          <>
            <h1 style={{ textAlign: 'center' }}>Loading page ...</h1>
            <PacmanLoader
              size={50}
              color={"#ff0000"}
              loading={true}
              css={{
                margin: 'auto'
              }}
            />
          </>
        )}  
        
        <Modal isOpen={this.state.showInlogModal}>
          <ModalHeader toggle={this.closeLoginDialog}>Log in is verlopen</ModalHeader>
          <ModalBody>
            <Login onSuccess={this.closeLoginDialog}/>
          </ModalBody>
        </Modal>
      </ApiContext.Provider>
    );
  }
}