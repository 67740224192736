import React, { useState } from 'react';
import {
  Collapse
} from 'reactstrap';
import classNames from 'classnames';
import './style.scss';

export const find = (root, isMatch, getChildren) => {
  const matches = [];  
  const pnode = (parent, node, depth=0) => {
    if (isMatch(node, depth)) {
      matches.push([parent, node]);
    }
    getChildren(node).forEach((child) => pnode(node, child, depth + 1));
  };
  root.forEach((child) => pnode(null, child, 0));
  return matches;
}

export default (props) => {
  let [opened, setOpened] = useState({});

  if (props.opened) {
    opened = props.opened;
    setOpened = () => {};
  }

  const toggle = (id) => {
    const n = {...opened};
    n[id] = !(n[id]);
    setOpened(n);
  }

  const treeRender = (nodes, parent=null, depth=0) => {
    const leftPadding = 20 * depth;
    const mapped = nodes.map((node, index, arr) => {
      const id = node.id;
      const toggleMe = () => toggle(id);
      const children = props.getChildren(node);
      const isLast = arr.length - 1 === index;
      const ToggleTriangle = (props) => {
        const clickFunc = props.onClick || (() => {});
        if (children.length > 0) {
          return (<span className={classNames('arrow-right', {'opened': opened[id]})} onClick={clickFunc}/>);
        }
        return (<span className={classNames('no-arrow-right')}/>);
      }
      
      return (
        <React.Fragment key={id}>
          {props.renderNode(node, parent, children, depth, leftPadding, ToggleTriangle, toggleMe, isLast, opened[id])}
          {(children.length > 0) && (
            <Collapse isOpen={opened[id]}>
              {treeRender(children, node, depth + 1)}
            </Collapse>
          )}
        </React.Fragment>
      );
    })    
    return mapped;
  };
  
  return (
    <>
      {treeRender(props.nodes)}
    </>
  )
}
