import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  ListGroup, 
  ListGroupItem, 
  Pagination,
  PaginationItem,
  PaginationLink,
  Button
} from 'reactstrap';
import { withApi } from 'Api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PacmanLoader from 'react-spinners/PacmanLoader';
import Carousel from 'components/carousel';
import Item from './item';
import classNames from 'classnames';
import Tree from 'components/tree';
import SizeBubble from 'components/sizeBubble';
import './style.scss';

const defaultFilter = {
  text: '',
  sizes: [],
  categories: [],
  sale: false,
  itemsPerPage: 60,
  pageIndex: 0,
  visibility: 'visible'
};

function encodeFilter(filter) {
  return encodeURI(btoa(JSON.stringify(filter)));
}

function decodeFilter(filter) {
  return JSON.parse(atob(decodeURI(filter)));
}

function attemptDecodeFilter(urlFilter) {
  let filter = defaultFilter;
  try {
    filter = decodeFilter(urlFilter);
  } catch (err) {
    console.error("could not deserialize the filter", err);
  }
  return filter;
}

export function getStoreForCategory(category) {
  const encodedFilter = encodeFilter({
    ...defaultFilter,
    categories: [category]
  });
  return "/store/" + encodedFilter;
}

export default withApi(({api, config, history, ...props}) => {
  const topOfStore = useRef(null);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [filterColIsOpen, setFilterColOpen] = useState(false);
  
  const filter = attemptDecodeFilter(props.match.params.filter);    
  const setFilter = (filter) => {
    const encodedFilter = encodeFilter(filter);
    history.push("/store/" + encodedFilter);
  }
  
  const scrollToTopOfStore = () => {
    const yOffset = -150; 
    const y = topOfStore.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo(0, y);
  }

  const insertUpdateItem = (item) => {
    const newItems = [...items];
    const index = newItems.findIndex(i => i.id === item.id);
    if (index === -1) {
      // did not exist yet
      newItems.unshift(item);
    } else {
      newItems[index] = item;
    }
    setItems(newItems);
  }
  
  const itemUpdatedByEditor = (item) => insertUpdateItem(item);
  
  const edit = (id) => {
    const child = window.open('/admin/items/'+id, '_blank');
    child.focus();
  }

  const copy = async (id) => {
    const copyOfItem = await api.copyItem(id);
    insertUpdateItem(copyOfItem);
    edit(copyOfItem.id);
  }
  
  const toggleVisible = async (id) => {
    insertUpdateItem(await api.toggleItemVisible(id));
  }
  
  const toggleDiscount = async (id) => {
    insertUpdateItem(await api.toggleItemDiscount(id));
  }
  
  const markHot = async (id) => {
    await api.markItemAsHot(id);
    api.addAlert('success', 'Het artikel is als hot gemarkeerd', '');
  }
  
  const trash = async (id) => {
    if (window.confirm("Weet je zeker dat je dit artikel wilt verwijderen?")) {
      const result = await api.removeItem(id);
      setItems(items.filter(i => i.id !== id));
      api.addAlert('success', result.msg, '');
    }
  }
      
  const filterSize = (id) => {
    let sizes;
    if (filter.sizes.includes(id)) {
      sizes = filter.sizes.filter(s => s !== id);
    } else {
      sizes = [...filter.sizes, id];
    }
    
    // store the result    
    setFilter({
      ...filter, 
      sizes,
      pageIndex: 0,
    });
  }
  
  const filterCategory = (id) => {
    let highestId = null;
    if (filter.categories.includes(id)) {
      // deselect the category and children
      const node = config.categories.find((n) => n.id === id);
      highestId = node.parent;
    } else {
      // select the category and parents
      highestId = id;
    }
    
    // collect all categories going upwards from this node
    let activeCategories = [];
    let curr = highestId;
    while (curr !== null) {
      activeCategories.push(curr);
      const tmpInnerCurr = curr;
      const node = config.categories.find((n) => n.id === tmpInnerCurr);
      curr = node.parent;
    }
    
    setFilter({
      ...filter, 
      categories: activeCategories,
      pageIndex: 0,
    });
  }
  
  const renderNode = (node, parent, children, depth, lpadding, ToggleTriangle, toggleMe, isLast, opened) => {
    return (
      <>
        <ListGroupItem 
          className={classNames(
            'category',
            {
              'rounded-0': parent, 
              'border-bottom-0': false, 
              'categoryActive': filter.categories.includes(node.id)
            }
          )}
          onClick={() => filterCategory(node.id)}
        >
          <div style={{ paddingLeft: `${lpadding}px` }}>
            <ToggleTriangle/>
            <span>
              {node.name}
            </span>
          </div>
        </ListGroupItem>      
      </>      
    );
  }
  
  const renderPaginator = (spreading=3) => {
    const totalPages = Math.max(Math.ceil(totalItems / filter.itemsPerPage), 1);
    const page = filter.pageIndex;
    const numbers = [];
    for (let i = spreading; i > 0; i--) {
      if ((page - i) >= 0) 
        numbers.push(page - i);
    }
    numbers.push(page);
    for (let i = 1; i <= spreading; i++) {
      if ((page + i) < totalPages)
        numbers.push(page + i);
    }
    
    const selectPage = (i) => {
      setFilter({
        ...filter, 
        pageIndex: i
      });
      scrollToTopOfStore();
    }
    
    return (
      <Pagination className="paginator">
        <PaginationItem disabled={page === 0}>
          <PaginationLink first onClick={() => selectPage(0)}/>
        </PaginationItem>
        <PaginationItem disabled={page === 0}>
          <PaginationLink previous onClick={() => selectPage(page - 1)}/>
        </PaginationItem>
        
        {numbers.map(i => (
          <PaginationItem key={i} active={i === page} onClick={() => selectPage(i)}>
            <PaginationLink>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem disabled={page === totalPages - 1}>
          <PaginationLink next onClick={() => selectPage(page + 1)}/>
        </PaginationItem>
        <PaginationItem disabled={page === totalPages - 1}>
          <PaginationLink last onClick={() => selectPage(totalPages - 1)}/>
        </PaginationItem>
      </Pagination>
    );
  }
  
  // updaters for the store page
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await api.getItems(filter);
      setTotalItems(res.total);
      setItems(res.items);
      setLoading(false);
    })();
  }, [props.match.params.filter])
  
  useEffect(() => {
    window.storeEventListenerNewItem = itemUpdatedByEditor;
    return () => {
      window.storeEventListenerNewItem = null;
    }
  });
  
  return (
    <Row>
      <Col md="3" className="filterCol">
        <div className="filterCol-toggler">
          <Button block className="my-4" onClick={() => setFilterColOpen(!filterColIsOpen)}>
            {filterColIsOpen ? 'sluit filter' : 'open filter'}
          </Button>
        </div>
        
        <div className={classNames({
          "filterCol-container": true,
          "closed": !filterColIsOpen
        })}>
          <InputGroup className="my-4">
            <Input 
              placeholder="zoek" 
              defaultValue={filter.text} 
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.target.blur();
                }
              }}
              onBlur={(e) => setFilter({...filter, text: e.target.value})} 
            />
            <InputGroupAddon addonType="append">
              <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
            </InputGroupAddon>
          </InputGroup>
  
          <hr/>
          <ListGroup className="mb-3">
            <Tree
              nodes={config.categories.filter(n => n.parent === null)} 
              renderNode={renderNode}
              getChildren={(node) => config.categories.filter(n => n.parent === node.id)}
              opened={Object.fromEntries(filter.categories.map(id => [id, true]))}
            />
          </ListGroup>
          <ListGroup className="mb-3 rounded-0">
            <ListGroupItem 
              className={classNames(
                'category',
                {
                  'categoryActive': filter.sale
                }
              )}
              onClick={() => setFilter({...filter, sale: !filter.sale})}
            >
              SALE
            </ListGroupItem>      
          </ListGroup>
          
          {api.loggedIn && (
            <>
              <hr/>
              <h3>Zichtbaarheid</h3>
              <Input type="select" value={filter.visibility} onChange={(e) => setFilter({...filter, visibility: e.target.value})}>
                <option value="both">Beide</option>
                <option value="visible">Alleen zichtbaar</option>
                <option value="invisible">Alleen ontzichtbaar</option>
              </Input>
            </>
          )}
          
          <hr/>
          <h3>Maten</h3>
          <div className="mb-3 text-center">
            {config.sizes.map(size => (
              <SizeBubble 
                key={size.id} 
                size={size} 
                select={filterSize} 
                active={filter.sizes.includes(size.id)}
              />
            ))}
          </div>
        </div>
        
        {filterColIsOpen && (
          <div className="filterCol-toggler">
            <Button block className="my-4" onClick={() => setFilterColOpen(!filterColIsOpen)}>
              {filterColIsOpen ? 'sluit filter' : 'open filter'}
            </Button>
          </div>
        )}
      </Col>

      <Col md="9">
        <Carousel items={config.storeBannerImages.map(i => {
          const newI = { ...i };
          newI.src = config.dynamic_banner_store_images + newI.src;
          return newI;
        })}/>
        
        <Row className="mb-4">
          <Col>
            <div ref={topOfStore}></div> 
          </Col>
        </Row>
        
        <Row>
          {isLoading ? (
            <Col>
              <h1 style={{ textAlign: 'center' }}>Artikelen aan het laden ...</h1>
              <PacmanLoader
                size={50}
                color={"#ff0000"}
                loading={true}
                css={{
                  margin: 'auto',
                  marginBottom: '100px'
                }}
              />
            </Col>
          ) : (items.map(item => (
            <Item 
              loggedIn={api.loggedIn} 
              config={config} 
              item={item} 
              key={item.id}
              edit={edit}
              copy={copy}
              toggleVisible={toggleVisible}
              toggleDiscount={toggleDiscount}
              markHot={markHot}
              trash={trash}
            />
          )))}
        </Row>
        
        <Row>
          <Col>
            {renderPaginator()}
          </Col>
        </Row>
      </Col>  
    </Row>
  );
});
