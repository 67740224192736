import React, { useState, useEffect } from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
  Input,
  Button
} from 'reactstrap';
import SizeBubble from 'components/sizeBubble';
import { splitTextOnNewLine } from 'formUtils';
import './style.scss';

export default withApi((props) => {
  const [product, setProduct] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentSize, setCurrentSize] = useState(null);
  const [currentQty, setCurrentQty] = useState(0);
  
  useEffect(() => {
    (async () => {
      const item = await props.api.getItem(props.match.params.id); 
      setCurrentImage(item.images[0]);
      setCurrentQty(1); 
      setCurrentSize(item.sizes[0]);
      setProduct(item);
    })();
  }, [props.api, props.match.params.id]);  
  if (product === null) return null;
  
  // the product has loaded
  let currentPagePrice = product.inDiscount ? product.discountPrice : product.price;
  let price = <>€ {product.price}</>;
  if (product.inDiscount) {
    price = (
      <>
        <span style={{ textDecoration: 'line-through', color: 'red', fontSize: '1rem' }} className="mr-2">€ {product.price}</span>
        <br/>
        € {product.discountPrice}
      </>
    );
  }

  return (
    <Row>
      <Col lg={5}>
        <div className="my-1 productEnlargedImage">
          <div className="content">
            <img src={props.config.dynamic_product_images + currentImage} alt="product enlarged"/>
          </div>
        </div>
        <div className="itemImagePickerContainer mb-3">
          {product.images.map((img) => (
            <img key={`im-${img}`} src={props.config.dynamic_product_images + img} className="mx-1" onClick={() => setCurrentImage(img)} alt="product picker"/>
          ))}
        </div>
      </Col>
      <Col lg={7}>
        <h1 className="my-4">{product.name}</h1>
        <p>
          {splitTextOnNewLine(product.description)}
        </p>
        <h3>
          {price}
        </h3>
        <hr/>
        <div className="mb-3">
          {product.sizes.sort((a, b) => a.size.order - b.size.order).filter(size => size.qty > 0).map(size => (
            <SizeBubble 
              key={size.sizeId} 
              size={size.size} 
              select={() => { setCurrentQty(1); setCurrentSize(size); }} 
              active={currentSize && currentSize.sizeId === size.sizeId}
            />
          ))}
        </div>
        
        {product.sizes.length === 0 && (
          <h3>Er zijn geen beschikbare maten meer voor dit artikel</h3>    
        )}
        { currentSize && (
          <Row>
            <Col sm={2} className="mb-4">
              <Input type="select" value={currentQty} onChange={(e) => setCurrentQty(e.target.value)}>
                {[...Array(currentSize.qty).keys()].map(i => (
                  <option key={i} value={i + 1}>{i + 1} stuk(s)</option>  
                ))}
              </Input>
            </Col>
            <Col className="mb-4">
              <Button block onClick={() => props.api.addToCart(product.id, currentSize.sizeId, currentQty, currentPagePrice)}>
                In het winkelwagentje
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
})
