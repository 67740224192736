import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import { FormGroup, Col, Label, Input, CustomInput, Button } from 'reactstrap';

function create_input_props(comp, path, typed) {
  return {
    id: path,
    value: get(comp.state, path),
    onChange: (event) => comp.formOnChange(path, typed(event.target.type === "checkbox" ? event.target.checked : event.target.value)),
    'data-test-id': path,
  };
}

export function splitTextOnNewLine(txt) {
  return txt.split('\n').map((item, i, arr) => {
    return (
      <React.Fragment key={i}>
        {item}
        {arr.length - 1 !== i && <br/>}
      </React.Fragment>
    );
  });
}

export const JoditConfig = {
  filebrowser: {
    ajax: {
      url: '/jodit/index.php'
    }
  },
  uploader: {
    url: '/jodit/index.php?action=fileUpload',
  }
};

export function getQueryParam(props, field) {
  if (props && props.match && props.match.params && props.match.params[field]) {
    return props.match.params[field];
  }
  return false;
}

export function setStateAsync(comp) {
  return (newState) => new Promise((resolve) => comp.setState(newState, resolve));
}

export function formStateProxy(state, setState) {
  const proxy = {
    state,
    setState
  };
  proxy.formOnChange = formOnChange(proxy);
  return proxy;
}

export function formOnChange(comp) {
  return (path, value) => {
    const newState = cloneDeep(comp.state);
    set(newState, path, value);
    comp.setState(newState);
  }
}

export function formInput(comp) {
  return (path, title, type="text", props={}, typed=(v) => v) => (
    <FormGroup row>
      <Label md={4} for={path}>{title}</Label>
      <Col md={8}>
        <Input type={type} {...props} {...create_input_props(comp, path, typed)}/>
      </Col>
    </FormGroup>
  )
}

export function formCustomInput(comp) {
  return (path, title, type="text", props={}, typed=(v) => v) => (    
    <FormGroup row>
      <Label md={4} for={path}>{title}</Label>
      <Col md={8}>
        <CustomInput type={type} {...props} {...create_input_props(comp, path, typed)} />
      </Col>
    </FormGroup>
  )
};

export function formSelect(comp) {
  return (path, title, options, typed=(v) => v) => (
    <FormGroup row>
      <Label md={4} for={path}>{title}</Label>
      <Col md={8}>
        <Input type="select" {...create_input_props(comp, path, typed)}>
          {options}
        </Input>
      </Col>
    </FormGroup>
  )
};

export function formSelectButton282(comp) {
  return (path, title, options, btn_text, btn_click, typed=(v) => v) => (
    <FormGroup row>
      <Label md={2} for={path}>{title}</Label>
      <Col md={8}>
        <Input type="select" {...create_input_props(comp, path, typed)}>
          {options}
        </Input>
      </Col>
      <Col md={2}>
        <Button color="info" onClick={btn_click} block>
          {btn_text}
        </Button>
      </Col>
    </FormGroup>
  )
};

