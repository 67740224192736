import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import { 
  NavLink
} from 'react-router-dom';
import { splitTextOnNewLine } from 'formUtils'; 
import { SocialIcon } from 'react-social-icons';
import { withApi } from 'Api';
import './style.scss';

export default withApi((props) => {
  const colSize = props.config.footerUserColumn ? 4 : 6;
  const iconStyle = {height:'35px',marginLeft: '5px'};
  return (
    <footer className="py-5 bg-dark appFooter">
      <Container>
      	<Row>
      		<Col md={colSize}>
            <h4>
              Algemeen
            </h4>
            
            <NavLink to="/flatText/aboutUsText">
              Over ons
            </NavLink>
            <br/>
            <NavLink to="/contact">
              Contact
            </NavLink>
            <br/>
            <NavLink to="/flatText/verzendBeleidText">
              Verzenden & retourneren
            </NavLink>
            <br/>
            <NavLink to="/flatText/algemeneVoorwaardenText">
              Algemene voorwaarden
            </NavLink>
            <br/>
            <NavLink to="/flatText/betaalMethodesText">
              Betaal methodes
            </NavLink>
            <br/>
            <NavLink to="/flatText/FAQText">
              FAQ
            </NavLink>
      		</Col>
      		{props.config.footerUserColumn && (
        		<Col md={colSize}>
              <h4>Onze gegevens</h4>
              {splitTextOnNewLine(props.config.footerUserColumn)}
        		</Col>
          )}
      		<Col md={colSize}>
            <h4>Volg ons</h4>
            <SocialIcon url={props.config.social.facebook} target="_blank" fgColor="white" className="mr-1" />
            <SocialIcon url={props.config.social.whatsapp} target="_blank" network="whatsapp" fgColor="white" />
            
            <br/><br/>
            <h4>Betalingsmogelijkheden</h4>
            <img src={process.env.PUBLIC_URL + '/iDEAL.png'} style={iconStyle} title="iDeal" />
            <img src={process.env.PUBLIC_URL + '/Overboeking.png'} style={iconStyle} title="Overboeken" />
            <img src={process.env.PUBLIC_URL + '/pin.svg'} style={iconStyle} title="Pinnen" />
            <img src={process.env.PUBLIC_URL + '/cash.svg'} style={iconStyle} title="Contant betalen" />
            
      		</Col>
      	</Row>
      	<Row className="mt-5">
      		<Col md="12">
      			<p className="m-0 text-center copy">Copyright &copy; 4Kids Kindermode & Kevin Kuurman</p>
      		</Col>
      	</Row>
      </Container>
    </footer>
  )
})