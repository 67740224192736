import React from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
} from 'reactstrap';
import get from 'lodash/get';

export default withApi((props) => {
  return (
    <Row className="my-4">
      <Col>
        <div dangerouslySetInnerHTML={{__html: get(props.api.config, props.contentKey)}} />
      </Col>
    </Row>
  );
});
