import React from 'react';
import {
  Row,
  Col,
  ListGroupItem, 
  Button,
  FormGroup,
  CustomInput,
} from 'reactstrap';
import { withApi } from 'Api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { formOnChange, formInput, formCustomInput, setStateAsync } from 'formUtils';
import classNames from 'classnames';
import Tree, { find } from 'components/tree';
import {
  SortableContainer, 
  SortableElement
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SizeBubble from 'components/sizeBubble';
import './style.scss';

const SortableItem = SortableElement(({appConfig, img, removeImage}) => {
  return (
    <div className="SortableImage mx-1">
      <img src={appConfig.dynamic_product_images + img} draggable={false} alt="product" />
      <span className="trash px-2" onClick={() => removeImage(img)}>
        <FontAwesomeIcon icon={faTrash} />
      </span>
    </div>
  );
});

const SortableList = SortableContainer(({appConfig, images, removeImage}) => {
  return (
    <div className="SortableImageContainer mb-3">
      {images.map((img, index) => (
        <SortableItem key={`item-${img}`} index={index} appConfig={appConfig} img={img} removeImage={removeImage} />
      ))}
    </div>
  );
});

export default withApi(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      price: '',
      discountPrice: '0.00',
      visible: false,
      inDiscount: false,
      categories: [],
      sizes: [],
      images: [],
      
      uploadingImages: false
    };
  }
  
  formOnChange = formOnChange(this);
  formInput = formInput(this);
  formCustomInput = formCustomInput(this);
  setStateAsync = setStateAsync(this);
  
  async componentDidMount() {
    if (this.props.match.params.id !== 'new') {
      const item = await this.props.api.getItem(this.props.match.params.id);
      this.setState(item);
    }
  }
  
  saveItem = async () => {
    const newItem = await this.props.api.saveItem(this.state);
    if (newItem.error) {
      alert(newItem.error);
      return;
    }
    
    if (window.opener && window.opener !== window) {
      try {
        if (window.opener.storeEventListenerNewItem) 
          window.opener.storeEventListenerNewItem(newItem);
        window.opener.focus(); 
        window.close();
      } catch(err) {
        console.log("Couldn't access opener", err);
      }
    } else if (this.props.match.params.id === 'new') {
      console.log(newItem.id);
      this.props.history.push('/admin/items/'+newItem.id);
    } else {
      alert("Het artikel is opgeslagen");
    }
  }
  
  addImages = async (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    this.setStateAsync({
      uploadingImages: true
    });
    const results = await this.props.api.uploadFiles(files, 'product');
    const images = [...this.state.images];
    results.forEach(r => {
      if (!r.success) {
        alert(r.original + " kon niet worden opgeslagen!");
        return;
      }
      images.push(r.name);
    })

    // store the result
    this.setState({
      uploadingImages: false,
      images
    })
  }
  
  removeImage = (img) => {
    this.setState({
      images: this.state.images.filter(i => i !== img)
    })
  }
  
  sizeSelected = (size) => {
    let sizes;
    if (this.state.sizes.find(s => s.sizeId === size.id)) {
      sizes = this.state.sizes.filter(s => s.sizeId !== size.id);
    } else {
      sizes = [...this.state.sizes, { 
        sizeId: size.id,
        qty: 0,
      }];
    }
    
    // store the result
    this.setState({
      sizes
    })
  }
  
  selectCategory = (category) => {
    let categories = [...this.state.categories];
    const treeRoots = this.props.config.categories.filter(n => n.parent === null);
    if (categories.includes(category.id)) {
      // remove and deselect all children
      categories = categories.filter(c => c !== category.id);
      const getChildren = (id) => this.props.config.categories.filter(n => n.parent === id);
      const removeChildren = (child) => {
        categories = categories.filter(c => c !== child.id);
        getChildren(child.id).forEach(removeChildren);
      };
      getChildren(category.id).forEach(removeChildren);
    } else {
      // insert and select all parents      
      let current = category.id;
      let parent;
      do {
        categories.push(current);
        const innerCurrent = current;
        [parent, ] = find(treeRoots, (node) => node.id === innerCurrent, this.getChildren)[0];
        current = parent ? parent.id : null;
      } while (current && !categories.includes(current));
    }
    
    this.setState({
      categories: categories
    })
  }

  getChildren = (node) => {
    return this.props.config.categories.filter(n => n.parent === node.id);
  }
  
  renderNode = (node, parent, children, depth, lpadding, ToggleTriangle, toggleMe, isLast, opened) => {    
    return (
      <>
        <ListGroupItem 
          className={classNames(
            'category',
            {
              'rounded-0': parent, 
              'border-bottom-0': false, 
              'categoryActive': Boolean(this.state.categories.includes(node.id))
            }
          )}
          onClick={() => this.selectCategory(node)}
        >
          <div style={{ paddingLeft: `${lpadding}px` }}>
            <ToggleTriangle/>
            <span>
              {node.name}
            </span>
          </div>
        </ListGroupItem>      
      </>      
    );
  }
  
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      images: arrayMove(this.state.images, oldIndex, newIndex),
    });
  };
  
  render() {
    return (
      <Row className="my-4">
        <Col>
          <h3>Artikel informatie</h3>
          {this.formInput('name', 'Artikel naam')}
          {this.formInput('description', 'Omschrijving', 'textarea', { rows: 5})}
          {this.formInput('price', 'Artikel prijs')}
          {this.formInput('discountPrice', 'Aanbiedings prijs')}
          {this.formCustomInput('visible', 'Zichtbaar', "switch", { checked: this.state.visible })}
          {this.formCustomInput('inDiscount', 'In de aanbieding', "switch", { checked: this.state.inDiscount })}
          <hr/>
          
          <h3>Categorie informatie</h3>
          <Tree
            nodes={this.props.config.categories.filter(n => n.parent === null)} 
            renderNode={this.renderNode}
            getChildren={this.getChildren}
            opened={Object.fromEntries(this.state.categories.map(c => [c, true]))}
          />
          <hr/>

          <h3>Maat informatie</h3>
          <div className="mb-3">
            {this.props.config.sizes.map(size => (
              <SizeBubble 
                key={size.id} 
                size={size} 
                select={(id) => this.sizeSelected(this.props.config.sizes.find(s => s.id === id))} 
                active={Boolean(this.state.sizes.find(s => s.sizeId === size.id))}
              />
            ))}
          </div>
          <div className="mb-3">
            {this.state.sizes.map((size, index) => {
              const sizeName = this.props.config.sizes.find(s => s.id === size.sizeId).name;
              return (
                <React.Fragment key={size.sizeId}>
                  {this.formInput(`sizes[${index}].qty`, `Inventaris voor ${sizeName}`, 'number')}
                </React.Fragment>
              );
            })}
          </div>
          <hr/>

          <h3>Afbeelding informatie</h3>
          <small>De afbeelding die vooraan staat komt op de artikel pagina</small>
          <SortableList 
            axis="x"
            lockAxis="x"
            distance={5}
            onSortEnd={this.onSortEnd} 
            images={this.state.images} 
            appConfig={this.props.config}
            removeImage={this.removeImage}
          />
          
          <FormGroup style={{ overflow: 'hidden' }}>
            <CustomInput type="file" id="imageUploadPicker" label="Kies een foto" onChange={this.addImages} multiple />
          </FormGroup>
          <hr/>
          

          <Button 
            block 
            className="mb-3" 
            onClick={this.saveItem}
            disabled={this.state.uploadingImages}
          >
            Sla op
          </Button>
        </Col>
      </Row>
    )
  }
});