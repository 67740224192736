import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import {
  Container
} from 'reactstrap';
import { withApi } from 'Api';
import Header from 'components/header';
import Footer from 'components/footer';
import Store from 'components/store';
import Login from 'components/login';
import Home from 'components/home';
import ShoppingCart from 'components/cart';
import ProductPage from 'components/item';
import SizesEditor from 'components/admin/sizes';
import CategoriesEditor from 'components/admin/categories';
import ItemsEditor from 'components/admin/item';
import SettingsEditor from 'components/admin/settings';
import CreateOrder from 'components/orders/create';
import OrdersList from 'components/orders';
import OrderView from 'components/orders/view';
import ContactForm from 'components/contact';
import FlatText from 'components/flatText';
import './App.scss';

const AdminRoute = withApi((props) => {
  if (!props.api.loggedIn) {
    return null;
  }
  return <Route {...props} />;
});

function App() {
  return (
    <div className="App">
      <div className="AppContent">
        <Header/>
        <Container fluid>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/store/:filter?" component={Store} />
            <Route exact path="/item/:id" component={(props) => <ProductPage key={props.match.url} {...props}/>} />
            <Route exact path="/shoppingcart" component={ShoppingCart} />
            <Route exact path="/admin" component={Login} />
            <Route exact path="/create_order" component={CreateOrder} />
            <Route exact path="/order/:id/:code" component={OrderView} />
            <Route exact path="/contact" component={ContactForm} />
            <Route exact path="/flatText/:key" component={(props) => <FlatText contentKey={props.match.params.key} />} />
            <AdminRoute exact path="/admin/sizes" component={SizesEditor} />
            <AdminRoute exact path="/admin/categories" component={CategoriesEditor} />
            <AdminRoute exact path="/admin/items/:id" component={ItemsEditor} />
            <AdminRoute exact path="/admin/settings" component={SettingsEditor} />
            <AdminRoute exact path="/admin/orders" component={OrdersList} />
            <Redirect to="/" />
          </Switch>
        </Container>
      </div>

      <Footer/>
      
      
      <CookieConsent
        buttonText="OK"
        cookieName="cookieConsentAccepted"
        style={{ background: "#2B373B" }}
        buttonStyle={{ fontWeight: "bold" }}
      >
        Cookies op deze site worden enkel gebruikt om uw winkelwagen bij te houden.
      </CookieConsent>
    </div>
  );
}

export default App;
