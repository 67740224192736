import React, { useState } from 'react';
import { 
  NavLink as RRNavLink
} from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Navbar,
  Container,
  NavItem,
  NavLink,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { withApi } from 'Api';
import { getStoreForCategory } from 'components/store';
import ShoppingCart from './shoppingCart'
import './style.scss';

export default withRouter(withApi((props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  const openAdminTool = (url, newTab) => {
    if (newTab) {
      const child = window.open(url + '?editor=true', '_blank');
      child.focus();
    } else {
      props.history.push(url);
    }
  }

  const marginClasses = "mx-3";
  let visitCounter = null;
  if (props.api.loggedIn && props.config.websiteViewCounter && props.config.websiteViewCounter[0]) {
    visitCounter = (<>({props.config.websiteViewCounter[0].counter})</>);
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (window.opener && window.opener !== window && urlParams.get('editor') === 'true') {
    return (
      <Navbar expand="lg" fixed="top" className="navbar-dark bg-dark">
        <Container fluid>
          <NavbarBrand tag={RRNavLink} to={"/"}>4Kids kindermode {visitCounter}</NavbarBrand>
          <NavbarToggler onClick={toggle}/>
          
          <Collapse isOpen={isOpen} navbar>
            <Nav className="abs-center-x" navbar>
              <NavItem 
                className={marginClasses} 
                onClick={() => {
                  try {
                    window.opener.focus();
                    window.close();
                  } catch(err) {
                    console.log("Couldn't access opener", err);
                  }
                }}
              >
                <NavLink>
                  Editor
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar expand="lg" fixed="top" className="navbar-dark bg-dark">
      <Container fluid>
        <NavbarBrand tag={RRNavLink} to={"/"}>4Kids kindermode {visitCounter}</NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        
        <Collapse isOpen={isOpen} navbar>
          <Nav className="abs-center-x" navbar>
            {props.config.categories.filter(i => i.inMenuBar).map((i, index) => (
              <NavItem className={marginClasses} key={index}>
                <NavLink tag={RRNavLink} to={getStoreForCategory(i.id)} onClick={close} activeClassName="active">
                  {i.name}
                </NavLink>
              </NavItem>
            ))}
            {props.api.loggedIn && (
              <UncontrolledDropdown nav inNavbar className={marginClasses}>
                <DropdownToggle nav>
                  Administratie
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => openAdminTool('/admin/items/new', true)}>
                    Artikel toevoegen
                  </DropdownItem>
                  <DropdownItem onClick={() => openAdminTool('/store/', false)}>
                    Artikel overzicht
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem onClick={() => openAdminTool('/admin/sizes/', true)}>
                    Maten
                  </DropdownItem>
                  <DropdownItem onClick={() => openAdminTool('/admin/categories/', true)}>
                    Categorieën
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem onClick={() => openAdminTool('/admin/settings/', true)}>
                    Website settings
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem onClick={() => openAdminTool('/admin/textTemplates/', true)}>
                    Verwijder ongebruikte resources
                  </DropdownItem>
                  <DropdownItem onClick={() => openAdminTool('/admin/orders/', false)}>
                    Order lijst
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem onClick={props.api.logout}>
                    Log uit
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            <ShoppingCart close={close} marginClasses={marginClasses} {...props}/>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}));