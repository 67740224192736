import React, { useState, useEffect } from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import { formStateProxy, formInput, formSelect, splitTextOnNewLine } from 'formUtils';
import ShoppingCart from 'components/cart';

const PaymentNames = {
  'ideal': 'iDeal',
  'manual': 'Handmatige overschrijving',
  'inPerson': 'Betalen bij het ophalen'
}

const UserDetails = ({ api, input, setStep, select, state }) => {
  return (
    <Row className="my-4">
      <Col>
        {input('name', 'Naam')}
        {input('email', 'E-mail', 'email')}
        {input('street', 'Straatnaam')}
        {input('houseNumber', 'Huisnummer')}
        {input('postalCode', 'Postcode')}
        {input('city', 'Plaatsnaam')}        
        {input('remarks', 'Opmerkingen', 'textarea')}
        {select('deliveryMethod', 'Verzenden/ophalen', (
          <>
            <option value="shipping">Verzenden (€ {api.shoppingCart.shippingPrice})</option>
            <option value="pickup">Ophalen op afspraak (geen verzendkosten)</option>
          </>
        ))}
        {select('payment', 'betaal methode', (
          <>
            <option value="ideal">{PaymentNames['ideal']}</option>
            <option value="manual">{PaymentNames['manual']}</option>
            {state.deliveryMethod === "pickup" && (
              <option value="inPerson">{PaymentNames['inPerson']}</option>
            )}
          </>
        ))}
        <Button block onClick={() => setStep(1)}>
          Controleer de bestelling
        </Button>
      </Col>
    </Row>
  )
}

const Verification = ({ api, setStep, state, history }) => {
  const placeOrder = async () => {
    try {
      const order = await api.createOrder(state);
      if (order.checkoutUrl) {
        window.location.replace(order.checkoutUrl);
      } else {
        history.push(order.orderPage);
      }
    } catch (err) {
      setStep(0);
    }
  }

  return (
    <>
      <Row className="my-4">
        <Col>
          <h3>Controleer de bestelling</h3>
        </Col>
      </Row>

      <ShoppingCart 
        isVerification={true}
        shippingPrice={state.deliveryMethod === 'pickup' ? '0,00' : api.shoppingCart.shippingPrice}
        totalPrice={state.deliveryMethod === 'pickup' ? api.shoppingCart.totalPriceNoShipping : api.shoppingCart.totalPrice}
      />

      <Row className="my-4">
        <Col>
          <b>Gekozen verzendmethode</b>
          <br/>
          {state.deliveryMethod === 'pickup' ? 'Ophalen op afspraak' : 'Bestelling wordt verzonden'}
        </Col>      
      </Row>

      <Row className="my-4">
        <Col>
          <b>Betaalmethod</b>
          <br/>
          {PaymentNames[state.payment]}
        </Col>      
      </Row>
      
      <Row className="my-4">
        <Col>          
          <b>Verzendadres/factuuradres</b><br/>
          {state.name}<br/>
          {state.street} {state.houseNumber}<br/>
          {state.postalCode} {state.city}<br/><br/>
          <b>Opmerkingen</b><br/>
          {splitTextOnNewLine(state.remarks)}
        </Col>
      </Row>
              
      <Row className="my-4">
        <Col sm="3" className="my-1">
          <Button block onClick={() => setStep(0)} color="danger">
            Terug
          </Button>
        </Col>
        <Col sm="9" className="my-1">
          <Button block onClick={placeOrder} color="success">
            Bestelling afronden
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withApi((props) => {
  const steps = [UserDetails, Verification];
  const [step, setStep] = useState(0);
  const [state, setState] = useState({
    name: '',
    email: '',
    postalCode: '',
    houseNumber: '',
    street: '',
    city: '',
    remarks: '',
    deliveryMethod: 'shipping',
    payment: 'ideal',
  });
  const proxy = formStateProxy(state, setState);
  const input = formInput(proxy);
  const select = formSelect(proxy);
  
  useEffect(() => {
    if (state.deliveryMethod !== "pickup" && state.payment === "inPerson") {
      setState({
        ...state,
        payment: 'ideal'
      })
    }
  }, [state.deliveryMethod]);
  
  if (props.api.shoppingCart.rows.length === 0) {
    return (
      <Row className="my-4">
        <Col className="text-center">
          <h2>Er zijn geen artikelen aanwezig in uw winkelwagen</h2>
        </Col>
      </Row>
    );
  }

  const StepComponent = steps[step];
  return (
    <StepComponent
      history={props.history}
      api={props.api}
      input={input}
      select={select}
      setStep={setStep}
      state={state}
      setState={setState}
    />
  )
});
