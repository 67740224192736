import React from 'react';
import { 
  NavLink as RRNavLink
} from 'react-router-dom';
import {
  NavItem,
  NavLink,
  Badge,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  return (
    <NavItem className={props.marginClasses}>
      <NavLink tag={RRNavLink} to={"/shoppingcart"} onClick={props.close} activeClassName="active" className={"text-nowrap"}>
        <span className="position-relative">
          Winkelwagen <FontAwesomeIcon icon={faShoppingCart} />
          <div className="counter">
            <Badge>{props.api.shoppingCart.rows.length}</Badge>
          </div>
        </span>
      </NavLink>
    </NavItem>
  );
};