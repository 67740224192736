import React from 'react';
import {
  withApi
} from 'Api';
import {
  Row,
  Col,
  Button, 
  Form
} from 'reactstrap';
import SizeBubble from 'components/sizeBubble';
import { formOnChange, formInput } from 'formUtils';
import {
  SortableContainer, 
  SortableElement
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({size, removeSize, selectSize, activeId}) => {
  return (
    <SizeBubble 
      key={size.id} 
      size={size} 
      select={selectSize} 
      remove={removeSize} 
      active={activeId === size.id}
    />
  );
});

const SortableList = SortableContainer(({sizes, removeSize, selectSize, activeId}) => {
  return (
    <div>
      {sizes.map((size, index) => (
        <SortableItem key={`size-${size.id}`} index={index} size={size} removeSize={removeSize} selectSize={selectSize} activeId={activeId}/>
      ))}
    </div>
  );
});

export default withApi(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: [],
      
      currId: null,
      currInput: '',
    };
  }

  formOnChange = formOnChange(this);
  formInput = formInput(this);
  
  async componentDidMount() {
    this.setState({
      sizes: await this.props.api.getSizes()
    });
  }
  
  select = (id) => {
    const size = this.state.sizes.find(i => i.id === id);
    this.setState({
      currId: (id === this.state.currId ? null : id),
      currInput: (id === this.state.currId ? '' : size.name)
    });
  }
  
  save = async e => {
    e.preventDefault();
    if (!this.state.currInput) {
      return;
    }
    
    const result = await this.props.api.saveSize(this.state.currId, this.state.currInput);
    const currentSizes = [...this.state.sizes];
    if (this.state.currId === null) {
      currentSizes.push(result);
    } else {
      const index = currentSizes.findIndex(item => item.id === result.id);
      currentSizes[index] = result;
    }
    
    this.setState({
      sizes: currentSizes,
      currInput: '',
      currId: null
    })
  }
  
  remove = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Weet je zeker dat je deze maat wilt verwijderen?")) {
      await this.props.api.removeSize(id);
      this.setState({
        sizes: [...this.state.sizes].filter(i => i.id !== id),
        currId: (this.state.currId === id ? null : this.state.currId)
      })
    }
  }
  
  onSortEnd = async ({ oldIndex, newIndex }) => {
    const reorderedSizes = arrayMove(this.state.sizes, oldIndex, newIndex);
    this.props.api.sizesReorder(reorderedSizes.map(s => s.id));
    this.setState({
      sizes: reorderedSizes,
    });
  };
  
  render() {
    return (
      <>
        <Row>
          <Col>
            <h1 className="my-4">Geregistreerde maten</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={{size: 8, order: 2}} className="mb-5">
            <SortableList 
              axis="xy"
              distance={5}
              onSortEnd={this.onSortEnd} 
              
              activeId={this.state.currId}
              sizes={this.state.sizes} 
              selectSize={this.select}
              removeSize={this.remove}
            />
          </Col>  
          <Col lg={{size: 4, order: 1}} className="mb-5">
            <Form onSubmit={this.save}>
              {this.formInput('currInput', 'name')}
              <Button color="primary" block>Save</Button>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
});