import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export default (props) => {
  return (
    <div className={classNames('sizeCircle', 'mr-3', { active: props.active})} onClick={() => props.select(props.size.id)}>
      <span className="size">
        {props.size.name}
      </span>
      {props.remove && (
        <span className="trash" onClick={(e) => props.remove(e, props.size.id)}>
          <FontAwesomeIcon icon={faTrash} />
        </span>
      )}
    </div>
  );
}
