import React from 'react';
import Carousel from 'components/carousel';
import {
  Alert
} from 'reactstrap';
import {
  splitTextOnNewLine
} from 'formUtils';
import { withApi } from 'Api';

export default withApi((props) => {
  return (
    <>
      <Carousel items={props.config.homeBannerImages.map(i => {
        const newI = { ...i };
        newI.src = props.config.dynamic_banner_home_images + newI.src;
        return newI;
      })}/>
      
      {props.config.homePageBanner && (
        <Alert color="success" className="appBanner mx-4">
          {splitTextOnNewLine(props.config.homePageBanner)}
        </Alert>
      )}
      
      <p className="mx-4">
        <div dangerouslySetInnerHTML={{__html: props.config.homePageText}} />
      </p>
    </>
  )
});
