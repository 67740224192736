import React, { useState, useEffect } from 'react';
import { withApi } from 'Api';
import {
  Row,
  Col,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

export default withApi((props) => {
  const [orders, setOrders] = useState([]);
  
  useEffect(() => {
    (async () => {
      setOrders(await props.api.getOrders());
    })();
  }, [props.api]);
  
  console.log(orders)
  return (
    <Row className="my-4">
      <Col>
        {orders.map(order => {
          return (
            <>
              <Row>
                <Col sm="1">
                  {order.id}
                </Col>

                <Col sm="3">
                  <b>{order.purchaserName}</b>
                  <br/>
                  {order.purchaserEmail}
                </Col>
        
                <Col sm="3">
                  {order.status}
                </Col>
        
                <Col sm="3">
                  € {order.totalPrice}
                </Col>
                
                <Col sm="2">
                  <NavLink to={`/order/${order.id}/${order.code}`}>
                    Open
                  </NavLink>
                </Col>
              </Row>
              <hr/>
            </>
          );
        })}
      </Col>
    </Row>
  );
});
